import { Component, OnInit } from '@angular/core';
import { LocalStorage } from './service/localStorage.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [LocalStorage]
})
export class AppComponent{
  title = 'jakglosowali.pl';
  public disable: boolean = false;
}
