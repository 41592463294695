import { Component } from '@angular/core';

@Component({
  selector: 'app-clubs',
  standalone: false,
  templateUrl: './clubs.component.html',
  styleUrl: './clubs.component.scss'
})
export class ClubsComponent {

}
