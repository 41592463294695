<div class="content" *ngIf="!mobile">
    <a class="logo" [routerLink]="'/'">
        <img src="/assets/favicon.png" alt="">
        <div class="logo-name">jakglosowali.pl</div>
    </a>

    <div class="links" [ngClass]="logedUser ? 'loged':'notLoged'">
        <a [routerLink]="'/'" [class.active]="getActiveRoute('/')">
            <span class="pi pi-home"></span>
            Strona główna
        </a>
        <a [routerLink]="'/glosowanie'" [class.active]="getActiveRoute('/glosowanie')">
            <span class="pi pi-comments"></span>
            Głosowania
        </a>
        <a [routerLink]="'/poslowie'" [class.active]="getActiveRoute('/poslowie')">
            
            <span class="pi pi-user"></span>
            Posłowie</a>
        <div class="googleButton" *ngIf="!logedUser">
            <asl-google-signin-button type='standard' size='large' [width]="200"></asl-google-signin-button>
        </div>
    </div>
</div>

<div class="content" *ngIf="mobile">
    <a class="logo" [routerLink]="'/'">
        <img src="/assets/favicon.png" alt="">
        <div class="logo-name">jakglosowali.pl</div>
    </a>
    <div class="links">
        <div class="button">
            <button (click)="sidebarVisible = true" p-Button><i class="pi pi-th-large"></i></button>
        </div>
    </div>
</div>

        
<p-sidebar [(visible)]="sidebarVisible" position="right">
    <h3>Menu</h3>

    <div class="sidebar-links">
        <a [routerLink]="'/'" [class.active]="getActiveRoute('/')">
            <span class="pi pi-home"></span>
            Strona główna
        </a>
    </div>
    <div class="sidebar-links">
        <a [routerLink]="'/glosowanie'" [class.active]="getActiveRoute('/glosowanie')">
            <span class="pi pi-comments"></span>
            Głosowania
        </a>
    </div>
    <div class="sidebar-links">
        <a [routerLink]="'/poslowie'" [class.active]="getActiveRoute('/poslowie')">
            <span class="pi pi-user"></span>
            Posłowie
        </a>
    </div>



    <div class="googleButton" *ngIf="!logedUser">
        <asl-google-signin-button type='standard' size='large' [width]="200"></asl-google-signin-button>
    </div>
</p-sidebar>