import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { MPDto } from 'src/app/models/mp-dto.model';
import { SingleVoteDtoModel } from 'src/app/models/single-vote-dto.model';
import { VoteDto } from 'src/app/models/vote-dto.model';
import { VotingDescriptionModel } from 'src/app/models/voting-description.model';
import { VotingDto } from 'src/app/models/voting-dto.model';
import { LocalStorage } from 'src/app/service/localStorage.service';

@Component({
  selector: 'app-mp-votes',
  templateUrl: './mp-votes.component.html',
  styleUrl: './mp-votes.component.scss'
})
export class MpVotesComponent implements OnInit{
  public votes: SingleVoteDtoModel[];
  public posibleVotes: VotingDto[];
  public mpData: MPDto;
  public mps: MPDto[] = [];
  public userInput: {votingId: number, vote: string, rating: number}[] = [];
  public userInputOnCurent: {votingId: number, vote: string, rating: number};
  public posibleSitings: number[] = [1,2,3,4,5];
  public activeVote: VotingDto = null;
  public votingDescription: VotingDescriptionModel[];
  public imageCache: any;
  public loading: boolean = true;
  public activeItem: any;
  public sameWithoutAbsend: number = 0;

  constructor(private localStorage: LocalStorage, private route: ActivatedRoute, private socialAuthService: SocialAuthService){}

  async ngOnInit(): Promise<void> {
    let id = this.route.snapshot.paramMap.get('id');
    let data = await this.localStorage.getData();

    this.mps = data.mps;
    this.mpData = this.mps.find(a => a.id+"" == id);
    this.posibleVotes = data.posibleVotes;
    this.votingDescription = data.votingDescription;
    this.votes = (await this.localStorage.getAllVotes()).filter(a => a.MP==this.mpData.id);

    this.imageCache = await this.localStorage.getSingleMpPhoto(id);

    if(this.checkIfHasCookie()){
      let userVotes = this.localStorage.getUserInput();
      if(userVotes.length == 0){
        await this.localStorage.setUserInputFromDB();
        this.userInput = this.localStorage.getUserInput();
      } else {
        this.userInput = userVotes;
      }
    }

    this.socialAuthService.authState.subscribe(async (user) => {
      await this.localStorage.setUserInputFromDB();
      this.userInput = this.localStorage.getUserInput();
    });

    this.posibleSitings = [...new Set(this.posibleVotes.map(item => item.sitting))];
    
    this.closesScore();

    this.loading = false;
  }

  private checkIfHasCookie(){
    let cookies = document.cookie.split("; ");
    let active = cookies.find(x => x.startsWith("activeUser="));
    if(active){
        return true;
    }
    return false
  }

  public getVoting(id){
    return this.posibleVotes.find(x => x.votingId == id);
  }

  public isAlreadyVoted(votingId: number): boolean {
    return this.userInput.some((item) => item.votingId === votingId);
  }

  public getFilteredSitings(sittingId: number): any[]{
    return this.posibleVotes.filter(a => a.sitting == sittingId);
  }

  public setActiveVote(vote: VotingDto){
    this.activeVote = vote;
    this.userInputOnCurent = null;
    this.activeVote.additionalDescription = this.votingDescription.find(x => x.votingId == vote.votingId); 
    this.getMyLastVote();
  }

  public getMyLastVote(){
    this.userInputOnCurent = this.userInput.find(x => x.votingId == this.activeVote.votingId);
    return this.userInputOnCurent;
  }

  public getMp(mpId: number){
    let mp = this.mps.find(x => x.id == mpId);
    let name = mp.firstName;
    if(mp.secondName) name+= " "+mp.secondName;
    name+=" "+mp.lastName+" ("+mp.club+")";
    return name;
  }

  public getMpVote(activeVote: VotingDto){
    let item = this.votes.find(x => x.votingIdOutside == activeVote.votingId);
    if(item){
      return item.vote;
    }

    return null;
  }

  public getPosibleOptions(): any[]{
    return this.activeVote.on_list_options.split(",");
  }

  public closesScore(){
    this.mpData.closeScore = 0;
    this.mpData.sameVotes = 0;
    this.mpData.numberOfVotesSame = 0;

    this.votes.forEach(val =>{
      let userAnswer = this.userInput.find(x => x.votingId == val.votingIdOutside);

      if(userAnswer){
        this.mpData.numberOfVotesSame += userAnswer.rating;
        
        if(val.vote == "ABSTAIN" && userAnswer.vote != null){
          this.mpData.closeScore += 0.1*userAnswer.rating;
          this.mpData.sameVotes += 0.1*userAnswer.rating;
        }
      }

      if((userAnswer && (userAnswer.vote == val.vote || val.vote == "ABSTAIN")) && val.vote.split(',').length < 2){
        this.mpData.closeScore += 0.1*userAnswer.rating;
        this.mpData.sameVotes += 0.1*userAnswer.rating;
      } 



      if(userAnswer && val.vote.split(',').length > 1){
        let uAns = userAnswer.vote.split(',');
        let mpAns = val.vote.split(',');
        let length = mpAns.length;
        let positive = 0;

        for(let i =0; i < length; i++){
          if(mpAns[i] == uAns[i]){
            positive++;
          }
        }

        this.mpData.closeScore += Math.round((positive/length)*100)/100 *userAnswer.rating/10;
        this.mpData.sameVotes += (positive/length)*userAnswer.rating/10;
      }
    })
  }

  public getSimProc(simular: number){
    return Math.round(simular/this.getUserRating()*10000)/100;
  }

  public getUserRating(){
    let rating = 0;
    for(let item of this.userInput){
      rating+=item.rating;
    }

    return rating / 10;
  }
}
