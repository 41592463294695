import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';

import { SocialAuthService } from '@abacritt/angularx-social-login';
import { VotingDto } from 'src/app/models/voting-dto.model';
import { MPDto } from 'src/app/models/mp-dto.model';
import { VotingDescriptionModel } from 'src/app/models/voting-description.model';
import { LocalStorage } from 'src/app/service/localStorage.service';

@Component({
  selector: 'app-votes',
  providers: [MessageService, LocalStorage],
  templateUrl: './votes.component.html',
  styleUrl: './votes.component.scss'
})
export class VotesComponent implements OnInit {
  public posibleVotes: VotingDto[];
  public allVotes: any[] = [];
  public mps: MPDto[] = [];
  public closestMP: MPDto[] = [];
  public posibleSitings: number[] = [1,2,3,4,5];
  public votingDescription: VotingDescriptionModel[];
  public activeVote: any = null;
  public userInput: {votingId: number, vote: string, rating: number}[] = [];
  public userInputOnCurent: {votingId: number, vote: string, rating: number};
  public setOnList: string[] = [];
  public importanceRating: number;
  public userLoged: boolean = false; 
  public loading: boolean = true;
  public closesClubs: {club: string, closeScore: number, avg?: number, mps: MPDto[], numberOfVotes: number}[] = [];

  constructor(private http: HttpClient, private messageService: MessageService, 
    private socialAuthService: SocialAuthService, private localStorage: LocalStorage){ }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.socialAuthService.authState.subscribe(async (user) => {
      await this.localStorage.setUserInputFromDB();
      this.userInput = this.localStorage.getUserInput();
      this.userLoged = true;
    });

    await this.getSejmData();
    await this.checkIfHasCookie();

    this.loading = false;
  }

  private async checkIfHasCookie(){
    let cookies = document.cookie.split("; ");
    let active = cookies.find(x => x.startsWith("activeUser="));
    if(active){
        this.userLoged = true;
        await this.localStorage.setUserInputFromDB();
        this.userInput = this.localStorage.getUserInput();
    }
  }


  private async getSejmData(){
    let data = await this.localStorage.getData();
    this.posibleVotes = data.posibleVotes;
    this.mps = data.mps;
    this.votingDescription = data.votingDescription;
   
    this.activeVote = this.posibleVotes[0];

    this.posibleSitings = [...new Set(this.posibleVotes.map(item => item.sitting))];
  }

  public getFilteredSitings(sittingId: number): any[]{
    return this.posibleVotes.filter(a => a.sitting == sittingId);
  }

  public getPosibleOptions(): any[]{
    return this.activeVote.on_list_options.split(",");
  }

  public isAlreadyVoted(votingId: number): boolean {
    return this.userInput.some((item) => item.votingId === votingId);
  }

  public castVote(vote: string){
    const votingId = this.activeVote.votingId;
    const existingVoteIndex = this.userInput.findIndex(item => item.votingId === votingId);

    if(vote == "REMOVE"){
      if (existingVoteIndex !== -1) {
        this.userInput.splice(existingVoteIndex, 1);
      }
      this.messageService.add({severity: 'success', summary:  'Głos został usunięty'});
    } else {
      if (existingVoteIndex !== -1) {
        this.userInput[existingVoteIndex].vote = vote;
        this.userInput[existingVoteIndex].rating = this.importanceRating;
      } else {
        this.userInput.push({ votingId, vote, rating: this.importanceRating });
      }
  
      this.messageService.add({severity: 'success', summary:  'Głos został oddany'});
      this.localStorage.setUserInput(this.userInput);
    }
  }

  public castOnListVote(){
    const votingId = this.activeVote.votingId;
    const existingVoteIndex = this.userInput.findIndex(item => item.votingId === votingId);
    let posibleOptions = this.getPosibleOptions();

    if(this.setOnList.length > posibleOptions.length / 2){
      this.messageService.add({severity: 'error', summary:  'Głos nie został oddany', detail: "Wybrano za dużo opcji! Możliwy jest wybór jedynie połowy"});
      return;
    }

    let string = "";
    for(let item of posibleOptions){
      if (this.setOnList.includes(item)) {
        string += "YES,";
      } else {
        string +="NO,";
      }
    }
    string = string.trim().replace(/,\s*$/, '');

    if (existingVoteIndex !== -1) {
      this.userInput[existingVoteIndex].vote = string;
      this.userInput[existingVoteIndex].rating = this.importanceRating;
    } else {
      this.userInput.push({ votingId, vote: string, rating: this.importanceRating });
    }
    this.messageService.add({severity: 'success', summary:  'Głos został oddany'});
    this.localStorage.setUserInput(this.userInput);
  }

  public setActiveVote(vote: VotingDto){
    this.activeVote = vote;
    this.userInputOnCurent = null;
    this.activeVote.additionalDescription = this.votingDescription.find(x => x.votingId == vote.votingId); 
    this.setOnList = [];
    this.getMyLastVote();
    if(this.userInputOnCurent){
      this.importanceRating = this.userInputOnCurent.rating;
    } else {
      this.importanceRating = 10;
    }
  }

  public async checkSimilarity(checkIfAbsent: boolean) {
    if(this.allVotes.length == 0){
      this.allVotes = await this.localStorage.getAllVotes();
      this.mps.forEach(mp => {
          mp.votes = this.allVotes.filter(vote => vote.MP === mp.id);
          mp.closeScore = 0;
      });
    }

    this.mps.forEach(mp=>{
      mp.closeScore = 0;
      mp.sameVotes = 0;
      mp.numberOfVotesSame = 0;
      mp.votes.forEach(val =>{
        let userAnswer = this.userInput.find(x => x.votingId == val.votingIdOutside);

        if(userAnswer){
          if(val.vote){
            mp.numberOfVotesSame += userAnswer.rating;
          }

          if(val.vote == "ABSTAIN" && checkIfAbsent){
            mp.numberOfVotesSame -= userAnswer.rating;
          }

          if(userAnswer.vote == val.vote && val.vote.split(',').length < 2){
            mp.closeScore += 0.1*userAnswer.rating;
            mp.sameVotes += 0.1*userAnswer.rating;
          } 

          if(val.vote.split(',').length > 1){
            let uAns = userAnswer.vote.split(',');
            let mpAns = val.vote.split(',');
            let length = mpAns.length;
            let positive = 0;


            for(let i =0; i < length; i++){
              if(mpAns[i] == uAns[i]){
                positive++;
              }
            }

            mp.closeScore += Math.round((positive/length)*100)/100 *userAnswer.rating/10;
            mp.sameVotes += (positive/length)*userAnswer.rating/10;
          }
        }
      })
      mp.numberOfVotesSame = mp.numberOfVotesSame/10;
    })

    let mps = this.mps;

    if(checkIfAbsent){
      mps = mps.sort((a,b) => (b.sameVotes/b.numberOfVotesSame) - (a.sameVotes/a.numberOfVotesSame));
    } else {
      mps = mps.sort((a,b) => b.sameVotes - a.sameVotes);
    }

    this.closestMP = mps.slice(0, 10);

    this.closesClub(checkIfAbsent);
  }

  public async closesClub(checkIfAbsent: boolean){
    this.closesClubs = [];
    this.mps.forEach(mp => {
      const existingClub = this.closesClubs.find(club => club.club === mp.club);
      if(mp.lastName=="Kukiz")console.log(mp)

      if (existingClub) {
        existingClub.closeScore += mp.closeScore;
        existingClub.numberOfVotes+=mp.numberOfVotesSame;
        existingClub.mps.push(mp);
      } else {
        this.closesClubs.push({
          club: mp.club,
          closeScore: mp.closeScore,
          mps: [mp],
          numberOfVotes: mp.numberOfVotesSame
        });
      }
    });


    this.closesClubs.forEach(val =>{
      console.log(val.closeScore, val.numberOfVotes)
      val.avg = Math.floor(val.closeScore/val.numberOfVotes * 10000) / 100;
    })

    this.closesClubs.sort((a,b) => b.avg - a.avg)
  }

  public async updateVotes(){
    let transformedInput = this.userInput.map(input => {
      let votes = input.vote.split(',').map(vote => vote.trim() === 'YES' ? 1 : 0);
      return {
          id: input.votingId,
          v: votes,
          r: input.rating
      };
  });

  try{
    await this.http.post<any>(environment.apiUrl+"/updateUserVote", JSON.stringify(transformedInput), {withCredentials: true}).toPromise(); 
    this.messageService.add({severity: 'success', summary:  'Głosy zostały zaktualizowane!'});
  } catch(err){
    this.messageService.add({severity: 'error', summary:  'Nie udało się zaktualizować głosów'});

  }

  }

  public checkIfVoteCasted(): boolean{
    let find = this.userInput.find(x => x.votingId == this.activeVote.votingId);
    if(find) return true;
    return false;
  }

  public getMyLastVote(){
    this.userInputOnCurent = this.userInput.find(x => x.votingId == this.activeVote.votingId);
    return this.userInputOnCurent;
  }

  public getMp(mpId: number){
    let mp = this.mps.find(x => x.id == mpId);
    let name = mp.firstName;
    if(mp.secondName) name+= " "+mp.secondName;
    name+=" "+mp.lastName+" ("+mp.club+")";
    return name;
  }

  public getUserRating(){
    let rating = 0;
    for(let item of this.userInput){
      rating+=item.rating;
    }

    return rating / 10;
  }
  
  public round(number: number){
    return Math.round(number*100)/100;
  }

  public getSimProc(mp:MPDto){
    return Math.round(mp.sameVotes/mp.numberOfVotesSame*10000)/100;
  }
}
