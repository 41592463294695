import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MPDto } from 'src/app/models/mp-dto.model';
import { LocalStorage } from 'src/app/service/localStorage.service';

@Component({
  selector: 'app-mps-votes',
  standalone: false,
  providers: [LocalStorage],
  templateUrl: './mps-votes.component.html',
  styleUrl: './mps-votes.component.scss'
})
export class MpsVotesComponent implements OnInit{
  public mps: MPDto[];
  public visibleMps: MPDto[];
  public searchWords: string;
  public imageCache = {};

  constructor(private localStorage: LocalStorage, private router: Router){}

  async ngOnInit(): Promise<void> {
      this.mps = (await this.localStorage.getData()).mps.sort((a,b) =>{
        if (a.lastName < b.lastName) {
          return -1;
        }
        if (a.lastName > b.lastName) {
          return 1;
        }
        return 0;
      });

      this.visibleMps = [];

      
      this.imageCache = await this.localStorage.getMpsMiniPhoto();

      for (let i = 0; i < this.mps.length; i += 20) {
        let chunk = this.mps.slice(i, i + 20);
        this.visibleMps.push(...chunk);
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
  }

  public goToMp(mpId: number){
    this.router.navigate(["/poslowie/"+mpId])
  }

  public findMp(){
    this.visibleMps = this.mps.filter((a) => {
      let fl = a.firstName.toLowerCase()+" "+a.lastName.toLowerCase();
      return fl.includes(this.searchWords.toLowerCase());
    })
  }

  public identify(index, item){
    return item.name; 
 }

}
