<div class="content">
    <h3>Strona w BUDOWIE</h3>

    <div class="info-block">
        <div>
            <h4>To do</h4>
            <ul>
                <li>Dodać opis głosowań (... nie mam lepszej roboty niż oglądanie starych głosowań i dodawanie opisów kontekstowych)</li>
                <li>Dodać podstronę FAQ</li>
                <li>Dodać informacje o ciasteczkach</li>
                <li>Dodać poradniczek co jak działa</li>
                <li>Stworzenie strony do sprawdzania głosów partii /club/short-name</li>
                <li>Nowe pomysły</li>
                <li>Jeśli masz jakiś pomysł co można było by dodać do strony: admin&#64;jakglosowali.pl</li>
            </ul>
        </div>
        <div>
            <h4>Zrobione</h4>
            <ul>
                <li>Skrypt pobierający co 6 godzin listę nowych głosowań/nowych posłów</li>
                <li>Podstawowy widok pozwalający na głosowanie i zapisywanie swoich głosów</li>
                <li>Podstawowy skrypt porównywania głosów użytkownika do głosów posłów</li>
                <li>Szyfrowanie odpowiedzi na serwerze (odpowiedzi są wysyłane jedynie na prośbę użytkownika po kliknięciu w przycisk, po zalogowaniu są one wczytywane by dalej móć głosować)</li>
                <li>Dodano możliwość wyboru jak ważny jest dany głos</li>
                <li>Dodanie podstorny z głosami posłów</li>
            </ul>
        </div>
        <div>
            <h4>About me</h4>
            <ul>
                <li>Nudziło mi się więc zrobiłem stronkę z możliwością głosowań by ocenić który polityk nie tyle co mówi że jakoś głosuje ale za jego słowami idą czyny</li>
                <li>Jestem studentem informatyki, programuje strony od +/- 5 lat</li>
                <li>Nie jestem członkiem żadnej partii politycznej/młodzieżówki czy czegoś tego typu</li>
            </ul>
        </div>
    </div>


    <div class="club-blocks">
        <a class="club-block" [routerLink]="['/kluby/PiS']"><img src="/assets/club-logos/pis.png" alt=""></a>
        <a class="club-block" [routerLink]="['/kluby/KO']"><img src="/assets/club-logos/ko.png" alt=""></a>
        <a class="club-block" [routerLink]="['/kluby/Polska2050-TD']"><img src="/assets/club-logos/2050.png" alt=""></a>
        <a class="club-block" [routerLink]="['/kluby/PSL-TD']"><img src="/assets/club-logos/psl.png" alt=""></a>
        <a class="club-block" [routerLink]="['/kluby/Lewica']"><img src="/assets/club-logos/lewica.png" alt=""></a>
        <a class="club-block" [routerLink]="['/kluby/Konfederacja']"><img src="/assets/club-logos/konfa.png" alt=""></a>
        <a class="club-block" [routerLink]="['/kluby/Kukiz15']"><img src="/assets/club-logos/kukiz.png" alt=""></a>
    </div>
</div>