import { Injectable } from "@angular/core";
import { SingleVoteDtoModel } from 'src/app/models/single-vote-dto.model';
import { VotingDto } from "../models/voting-dto.model";
import { MPDto } from "../models/mp-dto.model";
import { VotingDescriptionModel } from "../models/voting-description.model";
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';
import { DomSanitizer } from "@angular/platform-browser";

@Injectable()
export class LocalStorage {
    private static allVotes: SingleVoteDtoModel[] = [];
    private static posibleVotes: VotingDto[] = [];
    private static mps: MPDto[] = [];
    private static votingDescription: VotingDescriptionModel[] = [];
    private static imageMiniCache = [];
    private static imageCache = [];
    private static userInput: {votingId: number, vote: string, rating: number}[] = [];
    private static isLoading: {allVotes: boolean, posibleVotes: boolean, mps: boolean, votingDescription: boolean} = {allVotes: false, posibleVotes: false, mps: false, votingDescription: false}

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}
     
    public async getData(): Promise<{
        posibleVotes: VotingDto[],
        mps: MPDto[],
        votingDescription: VotingDescriptionModel[]
    }> {
        if (LocalStorage.posibleVotes.length === 0 && !LocalStorage.isLoading.posibleVotes) {
            LocalStorage.isLoading.posibleVotes = true;
            LocalStorage.posibleVotes = await this.http.get<VotingDto[]>(environment.apiUrl + "/getVotingList").toPromise();
            LocalStorage.isLoading.posibleVotes = false;
        }

        if (LocalStorage.mps.length === 0 && !LocalStorage.isLoading.mps) {
            LocalStorage.isLoading.mps = true;
            LocalStorage.mps = await this.http.get<MPDto[]>(environment.apiUrl + "/getMPs").toPromise();
            LocalStorage.isLoading.mps = false;

        }

        if (LocalStorage.votingDescription.length === 0 && !LocalStorage.isLoading.votingDescription) {
            LocalStorage.isLoading.votingDescription = true;
            LocalStorage.votingDescription = await this.http.get<VotingDescriptionModel[]>(environment.apiUrl + "/getVotingDescription").toPromise();
            LocalStorage.isLoading.votingDescription = false;
        }

        return {
            posibleVotes: LocalStorage.posibleVotes,
            mps: LocalStorage.mps,
            votingDescription: LocalStorage.votingDescription
        };
    }

    public async getAllVotes(){
        if (LocalStorage.allVotes.length === 0 && !LocalStorage.isLoading.allVotes) {
            LocalStorage.isLoading.allVotes = true;
            LocalStorage.allVotes = await this.http.get<SingleVoteDtoModel[]>(environment.apiUrl + "/getVote").toPromise();
            LocalStorage.isLoading.allVotes = false;
        }
        return LocalStorage.allVotes
    }

    public async getMpsMiniPhoto(){
        if(Object.keys(LocalStorage.imageMiniCache).length === 0){
            LocalStorage.mps.forEach(mp => {
                const imageUrl = '/assets/mps/mini/' + mp.id + '.jpg';
                this.http.get(imageUrl, { responseType: 'blob' }).subscribe(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        LocalStorage.imageMiniCache[mp.id] = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
                    };
                    reader.readAsDataURL(blob);
                });
            });
        }

        return LocalStorage.imageMiniCache;
    }

    public async getMpsPhoto(){
        if(Object.keys(LocalStorage.imageCache).length === 0){
            LocalStorage.mps.forEach(mp => {
                const imageUrl = '/assets/mps/' + mp.id + '.jpg';
                this.http.get(imageUrl, { responseType: 'blob' }).subscribe(blob => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        LocalStorage.imageCache[mp.id] = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
                    };
                    reader.readAsDataURL(blob);
                });
            });
        }

        return LocalStorage.imageCache;
    }

    public async getSingleMpPhoto(mp: string){
        
        const imageUrl = '/assets/mps/' + mp + '.jpg';
        let result = await this.http.get(imageUrl, { responseType: 'blob' }).toPromise();
        let url = window.URL.createObjectURL(result);
        return url;
    }

    public getUserInput(){
        return LocalStorage.userInput;
    }

    public setUserInput(votes: {votingId: number, vote: string, rating: number}[]){
        LocalStorage.userInput = votes;
    }

    public async setUserInputFromDB(){
        let data = await this.http.post<any>(environment.apiUrl+"/getUserData", null, {withCredentials: true}).toPromise(); 

        LocalStorage.userInput = data.map(item => {
          let votes = item.v.map(vote => vote === 1 ? 'YES' : 'NO').join(',');
          return {
              votingId: item.id,
              vote: votes,
              rating: item.r
          };
        });
    }
}