import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast';
import { GoogleInitOptions, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule, GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { VotesComponent } from './components/votes/votes.component';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';
import { MpsVotesComponent } from './components/mps-votes/mps-votes.component';
import { MpVotesComponent } from './components/mp-votes/mp-votes.component';
import { RatingModule } from 'primeng/rating';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TabViewModule } from 'primeng/tabview';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ClubsComponent } from './components/clubs/clubs.component';
import { HomeComponent } from './components/home/home.component';

const googleLoginOptions: GoogleInitOptions = {
  oneTapEnabled: false
};

@NgModule({
  declarations: [
    AppComponent,
    VotesComponent,
    MenuBarComponent,
    MpsVotesComponent,
    MpVotesComponent,
    ClubsComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AccordionModule,
    BrowserAnimationsModule,
    CheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    ToastModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
    RatingModule,
    ProgressSpinnerModule,
    TabViewModule,
    SidebarModule,
    ButtonModule,
    ScrollPanelModule
  ],
  providers: [
    { 
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('458137881327-rmhlvbc5277rm96120suflfh7mi233et.apps.googleusercontent.com', googleLoginOptions)
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
