<div class="content">
    <div *ngIf="!loading" class="mpView">
        <div class="mpDetail">
            <img ngOptimizedImage [src]="imageCache" alt=""><br>
            {{mpData?.firstName}}
            {{mpData?.secondName}}
            {{mpData?.lastName}}<br>
            {{mpData?.club}}
            <i *ngIf="mpData"> ({{getSimProc(mpData.sameVotes)}}%) </i>            
        </div>
        <div class="votesList">
            <p-tabView [scrollable]="true" [(activeIndex)]="activeItem" [style]="{'width': '100%', 'margin': 'auto'}">
                <p-tabPanel *ngFor="let item of this.posibleSitings" [header]="'Posiedzenie '+item ">
                    <p-scrollPanel [style]="{ width: '100%', height: '150px' }">
                        <button class="selectVoting" [ngClass]="{ 'alreadyVoted': isAlreadyVoted(item2.votingId) }" *ngFor="let item2 of getFilteredSitings(item)" (click)="setActiveVote(item2)">{{item2.votingId}}</button>
                    </p-scrollPanel>
                </p-tabPanel>
            </p-tabView>
        </div>

        <div class="votes" *ngIf="activeVote">
            <div></div>
            <div>
                <h3>{{activeVote.title}}</h3>
                <h4>{{activeVote.description}}</h4>
                <h4 *ngIf="activeVote.description != activeVote.topic && activeVote.title != activeVote.topic">{{activeVote.topic}}</h4>
                <br>
            
                <div *ngIf="activeVote.additionalDescription" class="additional_info">
                    {{this.activeVote.additionalDescription.additional_info}}<br>
                    {{getMp(this.activeVote.additionalDescription.author_of_proposal)}}<br>
                </div>

                <div *ngIf="activeVote.kind == 'ON_LIST'">
                    <div *ngFor="let item of getPosibleOptions()" class="field-checkbox">
                        {{item}}
                    </div>
                </div>
                <br>

                <div>
                    Głos posła: {{getMpVote(activeVote)}}<br>
                    Twój głos: {{userInputOnCurent?.vote}}
                </div>
    
                <br>
    

                <br><br>
            </div>
            <div *ngIf="!activeVote">
            </div>
        </div>
    </div>
    
    <div *ngIf="loading" class="loading">
        <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
    </div>
</div>
