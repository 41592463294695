<div class="content" *ngIf="!loading">
    <div class="choose-siting">
        <p-tabView *ngIf="this.posibleSitings" [scrollable]="true" [style]="{'max-width': '900px','width': '80%', 'margin': 'auto'}">
            <p-tabPanel *ngFor="let item of this.posibleSitings" [header]="'Posiedzenie '+item ">
                <p-scrollPanel [style]="{ width: '100%', height: '150px' }">
                    <button class="selectVoting" [ngClass]="{ 'alreadyVoted': isAlreadyVoted(item2.votingId) }" *ngFor="let item2 of getFilteredSitings(item)" (click)="setActiveVote(item2)">{{item2.votingId}}</button>
                </p-scrollPanel>
            </p-tabPanel>
        </p-tabView>

        <div class="results">
            <button (click)="updateVotes()" *ngIf="userLoged" class="actionButton"> <i class="pi pi-cloud-upload"></i>
                Zaktualizuj głosy z chmurą</button>
            <button (click)="checkSimilarity(false)" class="actionButton">Sprawdź podobieństwo do posłów i partii</button>
            <button (click)="checkSimilarity(true)" class="actionButton">Sprawdź podobieństwo do posłów i partii (nie wliczaj nieobecności do wyniku)</button>
            <div *ngIf="closestMP">
                <div *ngFor="let mp of closestMP; let i = index">
                    {{i+1}}.
                    {{mp.firstName}}
                    {{mp.lastName}}
                    ({{mp.club}})<br>
                    {{round(mp.sameVotes)}} / {{mp.numberOfVotesSame}}
                    ({{getSimProc(mp)}}%)
                </div>
            </div>

            <div *ngIf="closesClubs">
                <br>
                <div *ngFor="let club of closesClubs; let i = index">
                    {{i+1}}. 
                    ({{club.club}}) &nbsp; {{club.avg}} %
                </div>
            </div>
        </div>
    </div>

    <div class="votes">
        <div></div>
        <div *ngIf="activeVote">
            <h3>{{activeVote.title}}</h3>
            <h4>{{activeVote.description}}</h4>
            <h4 *ngIf="activeVote.description != activeVote.topic && activeVote.title != activeVote.topic">{{activeVote.topic}}</h4>
            <br>
        
            <div *ngIf="activeVote.additionalDescription" class="additional_info">
                {{this.activeVote.additionalDescription.additional_info}}<br>
                {{getMp(this.activeVote.additionalDescription.author_of_proposal)}}<br>
            </div>

            <div *ngIf="userInputOnCurent">
                Zapisany głos: {{userInputOnCurent?.vote}}
            </div>

            Jak ważny jest dla ciebie ten głos:
            <p-rating [(ngModel)]="importanceRating" [stars]="10" [cancel]="false"></p-rating>

            <br><br>
            <div *ngIf="activeVote.kind == 'Electronic'" class="voting-group">
                <button class="votingButton yes" (click)="castVote('YES')">Tak</button>
                <button class="votingButton no" (click)="castVote('NO')">Nie</button>
                <button class="votingButton abstain" (click)="castVote('ABSTAIN')">Wstrzymuje się</button>
                <button class="votingButton remove" (click)="castVote('REMOVE')" *ngIf="getMyLastVote()">Usuń głos</button>
            </div>
            <div *ngIf="activeVote.kind == 'ON_LIST'">
                <div *ngFor="let item of getPosibleOptions()" class="field-checkbox">
                    <p-checkbox [label]="item" name="group" [value]="item" [(ngModel)]="setOnList"></p-checkbox>
                </div>
        
                <br>
                <button class="votingButton" (click)="castOnListVote()">Zapisz głos</button>
            </div>
        </div>
        <div *ngIf="!activeVote"></div>
    </div>


</div>
<div class="loading-content" *ngIf="loading">
    <div class="loading">
        <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="8" fill="var(--surface-ground)" animationDuration="1s"></p-progressSpinner>
    </div>
</div>

<p-toast></p-toast>