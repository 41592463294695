import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JWTService } from 'src/app/service/jwt.service';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrl: './menu-bar.component.scss',
  providers: [JWTService]
})
export class MenuBarComponent implements OnInit{
  public buttonVisible: boolean = true;
  public logedUser = null;
  public sidebarVisible: boolean = false;
  public mobile = false;
  
  constructor(private socialAuthService: SocialAuthService, private jwtServ: JWTService, private router: Router){ }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.mobile = window.innerWidth < 1000 ? true : false; 
  }

  async ngOnInit(): Promise<void> {
    this.socialAuthService.authState.subscribe(async (user) => {
      var now = new Date();
      var time = now.getTime();
      time += 3600 * 1000;
      now.setTime(time);
      document.cookie=`activeUser=${user.idToken}; path=/; expires='` + now.toUTCString();
      
      this.checkCookie();
    });

    this.checkCookie();
    this.mobile = window.innerWidth < 1000 ? true : false; 
  }

  private checkCookie(){
    let cookies = document.cookie.split("; ").find(x => x.startsWith("activeUser="));

    if(cookies){
      let data = this.jwtServ.parseJwt(cookies.split("=")[1]);
      this.buttonVisible = false;

      this.logedUser = data.given_name+" "+data.family_name;
    }
  }

  public getActiveRoute(url: string){
    if(url == this.router.url){
      return true;
    }
    return false;
  }

}
