<div class="content">
    <div class="search-bar">
        <input type="text" placeholder="Nazwisko posła" [(ngModel)]="searchWords" (ngModelChange)="findMp()">
    </div>
    
    <div class="mpsList">
        <div *ngFor="let item of visibleMps; trackBy: identify">
            <div class="mps" (click)="goToMp(item.id)">
                <img ngOptimizedImage [src]="imageCache[item.id]" alt=""> 
                <div class="mps-name">
                    {{item.lastName}} <br>
                    {{item.firstName}} <br>
                    ({{item.club}})
                </div>
            </div>
        </div>
    </div>
    
</div>
