import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VotesComponent } from './components/votes/votes.component';
import { HomeComponent } from './components/home/home.component';
import { MpsVotesComponent } from './components/mps-votes/mps-votes.component';
import { MpVotesComponent } from './components/mp-votes/mp-votes.component';
import { ClubsComponent } from './components/clubs/clubs.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'glosowanie', component: VotesComponent},
  {path: 'poslowie', component: MpsVotesComponent},
  {path: 'poslowie/:id', component: MpVotesComponent},
  {path: 'kluby/:id', component: ClubsComponent},
  {path: "**", component: HomeComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
